import React, { Component } from 'react'
import logo from './logo.svg'
import gif from './video.gif'
import styled, { createGlobalStyle } from 'styled-components'
import Seo from '../components/seo'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Menlo,Monaco,'Courier New',mono,monospace;
    background-color: #e8390e;
    font-size: 12px;
  }
`
const BgImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${gif});
  background-size: cover;
  background-position: center center;
`

const Links = styled.div`
  display: flex;
  flex-direction: row;
`
const Container = styled.div`
  overflow-x: hidden;
  -wekit-oveflow-scrolling: touch;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0,0,0,0.35);
`
const Logo = styled.img.attrs({
  width: 240,
  height: 240 / 142 * 32,
  src: logo
})`
  margin: 20px;
  flex-shrink: 0;
`
const P = styled.p`
  color: white;
  line-height: 1.5em;
  max-width: 300px;
  margin-bottom: 20px;
  text-align: center;
`
const Link = styled.a`
  margin-bottom: 20px;
  padding: 10px;
  color: white;
  text-decoration: none;
  &:hover {
    color: #e8390e;
  }
`

export default class App extends Component {
  render() {
    return (
      <Container>
        <GlobalStyle />
        <Seo title="Wombaroo" />
        <BgImage />
        <Container>
          <Logo />
          <P>
            爬虫類用サプリメント 「ウォンバルー」
            <br />
            <br />
            オーストラリアの動物園や研究所で使われている爬虫類用のサプリメント「ウォンバルー」 オーストラリア産の爬虫類はもちろん、他の爬虫類にも幅広くご利用いただけます。
            日本初上陸!!
          </P>
          <Links>
            <Link href='https://twitter.com/wombaroo_jp/'>Twitter</Link>
            <Link href='https://www.instagram.com/wombaroo_japan/'>Instagram</Link>
            <Link href='https://www.facebook.com/wombaroo.jp/'>Facebook</Link>
            <Link href='mailto:mail@wombaroo.jp'>mail@wombaroo.jp</Link>
          </Links>
        </Container>
      </Container>
    )
  }
}
